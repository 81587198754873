//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.table td {
	border-style: dashed;
}

.underline-menu-contract:hover {
	text-decoration: underline;
	cursor: pointer;
}

.card-hover:hover {
	cursor: pointer;
	box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1);
	border-radius: 8px;
}

.custom-placeholder::placeholder {
	color: white !important;
	opacity: 1;
}

.custom-placeholder::-ms-input-placeholder {
	color: white !important;
}

.ql-container {
	min-height: 10rem;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.ql-editor {
	height: 100%;
	flex: 1;
	overflow-y: auto;
	width: 100%;
}

.spinner-overlay-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.spinner-modal {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

.spinner-text {
	font-size: 16px;
	font-weight: bold;
	color: #333;
	text-align: center;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
